<template>
  <div class="comments-container">
    <!-- 评论表单 -->
    <div class="comment-form-section">
      <el-form ref="commentForm" :model="form">
        <el-form-item>
          <el-input
            v-model="form.content"
            type="textarea"
            :rows="3"
            placeholder="分享你的想法..."
            class="comment-input"
          />
        </el-form-item>
        <el-button type="primary" @click="submitComment" class="submit-btn">
          <i class="el-icon-s-promotion"></i> 发表评论
        </el-button>
      </el-form>
    </div>

    <el-divider class="custom-divider">
      <span class="divider-text">全部评论</span>
    </el-divider>

    <!-- 评论列表 -->
    <div class="comments-list">
      <div v-for="comment in comments" :key="comment.id" class="comment-item">
        <div class="comment-header">
          <div class="user-info">
            <el-avatar :size="40" :src="comment.avatar || 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'" />
            <div class="user-details">
              <span class="username">{{ comment.userName }}</span>
              <span class="comment-time">{{ comment.createTime }}</span>
            </div>
          </div>
          <div class="comment-actions">
            <el-button
              class="action-btn"
              :class="{ 'liked': comment.hasLiked }"
              type="text"
              @click="likeComment(comment)"
            >
              <i class="el-icon-star-off"></i>
              <span>{{ comment.likesCount }}</span>
            </el-button>
            <el-button
              class="action-btn"
              type="text"
              @click="toggleReplyInput(comment.id)"
            >
              <i class="el-icon-chat-dot-round"></i> 回复
            </el-button>
            <el-button
              class="action-btn"
              type="text"
              @click="deleteComment(comment.id,comment.topid,comments)"
            >
              <i class="el-icon-delete"></i> 删除
            </el-button>
          </div>
        </div>
        
        <div class="comment-content">
          {{ comment.content }}
        </div>

        <!-- 回复输入框 -->
        <div v-if="activeReplyId === comment.id" class="reply-input-section">
          <el-input
            v-model="replyContent"
            type="textarea"
            :rows="2"
            :placeholder="'回复'+ comment.userName"
            class="reply-input"
          />
          <div class="reply-actions">
            <el-button size="small" @click="cancelReply" class="cancel-btn">取消</el-button>
            <el-button size="small" type="primary" @click="submitReply(comment.id, comment)" class="submit-btn">
              发表回复
            </el-button>
          </div>
        </div>

        <!-- 回复列表 -->
        <div v-if="comment.replies && comment.replies.length > 0" class="replies-section">
          <div v-for="reply in comment.replies" :key="reply.id" class="reply-item">
            <div class="reply-header">
              <div class="user-info">
                <el-avatar :size="32" :src="reply.avatar || 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'" />
                <div class="user-details">
                  <span class="username">{{ reply.userName }}</span>
                  <span class="reply-time">{{ reply.createTime }}</span>
                </div>
              </div>
              <div class="reply-actions">
                <el-button
                  class="action-btn"
                  :class="{ 'liked': reply.hasLiked }"
                  type="text"
                  @click="likeComment(reply)"
                >
                  <i class="el-icon-star-off"></i>
                  <span>{{ reply.likesCount }}</span>
                </el-button>
                <el-button
                  class="action-btn"
                  type="text"
                  @click="toggleReplyInput(reply.id)"
                >
                  <i class="el-icon-chat-dot-round"></i> 回复
                </el-button>
                <el-button
                  class="action-btn"
                  type="text"
                  @click="deleteComment(reply.id,reply.topid,comment.replies)"
                >
                  <i class="el-icon-delete"></i> 删除
                </el-button>
              </div>
            </div>
            
            <div class="reply-content">
              回复 <span class="reply-to">@{{ reply.fathername }}</span>：{{ reply.content }}
            </div>

            <!-- 回复输入框 -->
            <div v-if="activeReplyId === reply.id" class="reply-input-section">
              <el-input
                v-model="replyContent"
                type="textarea"
                :rows="2"
                :placeholder="'回复'+ reply.userName"
                class="reply-input"
              />
              <div class="reply-actions">
                <el-button size="small" @click="cancelReply" class="cancel-btn">取消</el-button>
                <el-button size="small" type="primary" @click="submitReply(comment.id, reply)" class="submit-btn">
                  发表回复
                </el-button>
              </div>
            </div>
          </div>
        </div>

        <div v-if="comment.replies && comment.replies.length > 0" class="reply-count">
          <el-button type="text" @click="toggleReplies(comment.id)" class="view-replies-btn">
            {{ comment.replies.length }}条回复
            <i class="el-icon-arrow-down"></i>
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import api from "@/axios/api";
import {COMMENT_API} from "@/api/comment-api";
import {ACTION_API} from "@/api/action-api";

export default {
  props: {
    message: {
      type: String,
      required: true,
      default: '1'
    },
  },
  data() {
    return {
      likeevent:{
        userid:"",
        articleid:"",
        requestId: "",
        action:""
      },// 唯一请求ID
      activeReplyId: null, // 当前激活的回复ID
      replyContent: '',    // 回复内容
      form: {
        content: ''
      },
      comments: [],
    };
  },
  emits: ['custom-event'],
  methods: {
    async getcomment() {
      const res = await api.get(COMMENT_API.GET_COMMENT, {
        params: {
          userid: store.state.user.id,
          articleid: this.message,
        }
      })
            this.comments = res.result || [];

    },
    // 提交评论
    submitComment() {
      if (this.form.content) {
        const conment = {
          article_id: this.message,
          user_id: store.state.user.id,
          content: this.form.content,
        };
        api.post(COMMENT_API.POST_COMMENT, conment).then((res) => {
          const newComment = {
            id: res.result,
            content: this.form.content,
            userName: store.state.user.nickname,
            createTime: new Date().toLocaleString(),
            likesCount: 0,
            hasLiked: false,
            replies: []
          };
          this.comments.push(newComment);
          this.$emit('custom-event');
          this.form.content = ''; // 清空输入框
        })

      }
    },

    // 删除评论
    deleteComment(commentId,val) {
      api.get(COMMENT_API.DELETE_COMMENT, {
        params: {
          id: commentId,
          topid:val
        }
      }).then((res) => {
        this.getcomment()
        this.$emit('custom-event');
        console.log(res)
      })
    },

    // 回复评论
    // replyComment(commentId,reply) {
    //   const content = prompt('请输入回复内容');
    //   if (content) {
    //     const comment = this.comments.find(c => c.id === commentId);
    //     const newReply = {
    //       id: Date.now(),
    //       content,
    //       userName: '用户' + Math.floor(Math.random() * 100),
    //       createTime: new Date().toLocaleString(),
    //       replies: [] // 新回复没有子回复
    //     };
    //     comment.replies.push(newReply);
    //   }
    // },
    toggleReplyInput(replyId) {
      // 切换当前激活的回复输入框
      this.activeReplyId = this.activeReplyId === replyId ? null : replyId;
      this.replyContent = '';
    },
    submitReply(commentId, parentReply) {
      console.log(commentId, parentReply);
      if (this.replyContent.trim()) {
        const comment = this.comments.find(c => c.id === commentId);
        console.log(this.replyContent)
        const conment = {
          article_id: this.message,
          user_id: store.state.user.id,
          content: this.replyContent,
          top_comment_id: comment.id,
          parent_comment_id: parentReply.id,
        };
        api.post(COMMENT_API.POST_COMMENT, conment).then((res) => {
          const newReply = {
            id: res.result,
            content: this.replyContent,
            userName: store.state.user.nickname,
            createTime: new Date().toLocaleString(),
            likesCount: 0,
            hasLiked: false,
            fatherid: parentReply.id,
            fathername: parentReply.userName,
          };
          this.$emit('custom-event');
          comment.replies.push(newReply);
          this.cancelReply();
        })
      }

    },

    cancelReply() {
      this.activeReplyId = null;
      this.replyContent = '';
    },
    // 点赞评论
    async likeComment(comment) {
      // const comment = this.comments.find(c => c.id === commentId);
      // if (!comment.hasLiked) {
      //   comment.likesCount++;
      //   comment.hasLiked = true;
      // }

      this.likeevent.requestId = 5555; // 生成唯一ID
      this.likeevent.articleid = comment.id;
      this.likeevent.userid = store.state.user.id;
      try {
        this.likeevent.action = comment.hasLiked ? 'commnetunlike' : 'commnetlike';
        await api.post(ACTION_API.POST_ATTION_EVENT, this.likeevent);

        // 更新本地状态
        comment.hasLiked = !comment.hasLiked;
        comment.likesCount = parseInt(comment.likesCount, 10) + (comment.hasLiked ? 1 : -1);
      } catch (error) {
        this.$message.error(error.message || '操作失败');
      } finally {
        this.likeLoading = false;
      }
    },

    // 显示/隐藏回复
    toggleReplies(commentId) {
      const comment = this.comments.find(c => c.id === commentId);
      comment.showReplies = !comment.showReplies;
    }
  },
  created() {
    this.getcomment()
  }
};
</script>

<style scoped>
.comments-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.comment-form-section {
  background: #fff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
}

.comment-input {
  margin-bottom: 15px;
}

.submit-btn {
  float: right;
  padding: 10px 20px;
  border-radius: 20px;
  transition: all 0.3s ease;
}

.submit-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.custom-divider {
  margin: 30px 0;
}

.divider-text {
  color: #909399;
  font-size: 16px;
  font-weight: 500;
}

.comments-list {
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.comment-item {
  background: #fff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.comment-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
}

.comment-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.user-info {
  display: flex;
  align-items: center;
  gap: 12px;
}

.user-details {
  display: flex;
  flex-direction: column;
}

.username {
  font-weight: 600;
  color: #303133;
  font-size: 15px;
}

.comment-time, .reply-time {
  font-size: 12px;
  color: #909399;
  margin-top: 2px;
}

.comment-actions {
  display: flex;
  gap: 15px;
}

.action-btn {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 6px 12px;
  border-radius: 16px;
  transition: all 0.3s ease;
}

.action-btn:hover {
  background: #f5f7fa;
  color: #409eff;
}

.action-btn.liked {
  color: #f56c6c;
}

.comment-content {
  color: #303133;
  line-height: 1.6;
  font-size: 15px;
  margin: 15px 0;
}

.reply-input-section {
  margin: 15px 0;
  padding: 15px;
  background: #f5f7fa;
  border-radius: 8px;
}

.reply-input {
  margin-bottom: 10px;
}

.reply-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.replies-section {
  margin-top: 20px;
  padding-left: 20px;
  border-left: 2px solid #f0f0f0;
}

.reply-item {
  background: #f8f9fa;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 15px;
}

.reply-content {
  color: #606266;
  line-height: 1.5;
  font-size: 14px;
  margin: 10px 0;
}

.reply-to {
  color: #409eff;
  font-weight: 500;
}

.reply-count {
  margin-top: 15px;
  text-align: center;
}

.view-replies-btn {
  color: #909399;
  font-size: 14px;
  transition: all 0.3s ease;
}

.view-replies-btn:hover {
  color: #409eff;
}

/* 响应式设计 */
@media screen and (max-width: 768px) {
  .comments-container {
    padding: 10px;
  }
  
  .comment-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  
  .comment-actions {
    width: 100%;
    justify-content: flex-start;
  }
  
  .replies-section {
    padding-left: 10px;
  }
}
</style>
