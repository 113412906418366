<template>
  <div class="container">
    <portal to="float-modal">
      <div class="floating-sidebar">
        <el-tooltip class="item" effect="dark" :content="isLiked ? '取消点赞' : '点赞'" placement="left">
          <div
              class="icon"
              :class="{ 'liked': isLiked }"
              @click="handleLike"
              v-loading="likeLoading"
          >
            <i class="el-icon-thumb"></i>
            <span>{{ passage.likes }}</span>
            <!-- 加载动画 -->
          </div>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="评论" placement="left">
          <div class="icon" @click="scrollTo('comments')">
            <i class="el-icon-chat-line-round"></i>
            <span>{{ comments }}</span>
          </div>
        </el-tooltip>
      </div>
    </portal>
    <el-row style="margin:0 15% ;width: 100%;" :gutter="50">
      <el-col class="data" :span="18">
        <el-card shadow="never">
          <div class="article-info-title">{{ passage.title }}</div>
          <!-- 添加阅读数和日期 -->
          <div class="article-meta">
            <span class="view-count">阅读数: {{ passage.viewCount }}</span>
            <span class="publish-date">发布于: {{ passage.create_time }}</span>
          </div>

          <div>
            <v-md-preview :text="passage.content" style="text-align: left"></v-md-preview>
          </div>

          <div id="comments" class="content-section">
            <comments @custom-event="getcomments" :message="id"></comments>
          </div>
        </el-card>
      </el-col>

      <el-col class="data" :span="6">
        <el-card :body-style="{ padding: '20px' }" class="user-card">

          <!-- 用户头像 -->
          <div class="avatar buttom">
            <img :src="user.photourl" alt="User Avatar" />
          </div>

          <!-- 用户信息 -->
          <div class="user-info buttom">
            <h2>{{ user.nickname }}</h2>
            <p>{{ user.email }}</p>
          </div>

          <!-- 关注和空间 -->
          <div class="user-actions buttom">
            <button @click="followUser">关注</button>
            <button @click="visitSpace">访问空间</button>
          </div>

          <!-- 用户相关信息 -->
          <div class="user-stats buttom">
            <p><strong>点赞量:</strong> {{ user.likes }} </p>
            <p><strong>文章数:</strong> {{ user.articlesCount }} </p>
          </div>
        </el-card>
      </el-col>
    </el-row>  </div>
</template>

<script>
import {Portal} from "portal-vue";
import api from "@/axios/api";
import {ARTICLE_API} from "@/api/article-api";
import {ACTION_API} from "@/api/action-api";
import store from "@/store";
import Comments from "@/pages/article/comments.vue";
import {COMMENT_API} from "@/api/comment-api";



export default {

  data() {
    return {
      id:this.$route.query.id,
      passage: {
        likes:0
      },
      user: {},
      isLiked: false,      // 当前用户是否已点赞
      likeLoading: false,  // 点赞按钮加载状态

      likeevent:{
        userid:"",
        articleid:"",
        requestId: "",
        action:""
      },// 唯一请求ID
      comments:0
    };
  },
  methods: {
    getoneartical() {
      var id = this.$route.query.id;
      api.get(ARTICLE_API.GET_ONEARTICLE, {
        params: {
          user_id: store.state.user.id,
          document_id:id
        }
      }).then(res => {
        this.passage = res.result.article;
        this.user = res.result.articleUser;
      })
    },

    scrollTo(section) {
      const element = document.getElementById(section);
      if (element) {
        element.scrollIntoView({behavior: 'smooth'});
      }
    },
    followUser() {
      console.log(`${this.user.username} 已关注`);
    },
    visitSpace() {
      console.log(`访问 ${this.user.username} 的空间`);
    },

    async handleLike() {
      if(!store.state.user){
        return;
      }
      if (this.likeLoading) return; // 防止重复点击

      this.likeLoading = true;
      this.likeevent.requestId = 5555; // 生成唯一ID
      this.likeevent.articleid = this.$route.query.id;
      this.likeevent.userid = store.state.user.id;
      try {
        this.likeevent.action = this.isLiked ? 'unlike' : 'like';
        await api.post(ACTION_API.POST_ATTION_EVENT, this.likeevent);

        // 更新本地状态
        this.isLiked = !this.isLiked;
        this.passage.likes = parseInt(this.passage.likes, 10) + (this.isLiked ? 1 : -1);
      } catch (error) {
        this.$message.error(error.message || '操作失败');
      } finally {
        this.likeLoading = false;
      }
    },
    async checklike() {
      if(!store.state.user){
        return;
      }
      const res = await api.get(`/article/${this.$route.query.id}/${store.state.user.id}/like-status`);
      this.isLiked = res.result;
    },

    getcomments(){
      api.get(COMMENT_API.GET_COMMENTCOUNT, {
        params:{
          articleid:this.$route.query.id,
        }
      }).then(res => {
        this.comments=res.result;
      })
    }
  },

  components: {Comments, Portal},
  mounted() {
    this.getcomments();
    this.checklike();
    this.getoneartical()
  },
};
</script>

<style scoped>
/* 左侧固定悬浮栏样式 */
.floating-sidebar {
  position: fixed;
  top: 25%;
  left: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  z-index: 99999; /* 确保浮动的悬浮栏始终在最上层 */
}

.item {
  margin-bottom: 40px;
  cursor: pointer;
}

.icon {
  display: flex;
  align-items: center;
  font-size: 30px;
}

.icon span {
  margin-left: 5px;
  font-size: 16px;
}

/* 主内容样式 */
.container {
  display: flex;
  justify-content: space-between;
}

.user-card {
  width: 100%;
  margin-top: 20px;
}




.content-section {
  margin-bottom: 50px;
  padding: 20px;
  border-radius: 5px;
}

.content-section h3 {
  margin-top: 0;
}



.avatar img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.user-info h2 {
  margin: 8px 0;
  font-size: 20px;
}

.user-actions button {
  margin: 8px 4px;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
}

.user-actions button:hover {
  background-color: #0056b3;
}

.user-stats p {
  font-size: 14px;
  margin: 4px 0;
}

.buttom{
margin-bottom: 20px;
}

.article-meta {
  margin-top: 10px;
  font-size: 14px;
  color: #666;
}

.article-meta span {
  margin-right: 15px;
}

.view-count {
  font-weight: bold;
}

.publish-date {
  font-style: italic;
}
/* 点赞按钮状态 */
.icon {
  cursor: pointer;
  transition: all 0.3s;
  position: relative;

  &:hover {
    color: #409eff;
  }

  &.liked {
    color: #f56c6c;
  }
}

/* 加载动画 */
.loading-icon {
  animation: rotating 2s linear infinite;
  margin-left: 5px;
}

@keyframes rotating {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
</style>
